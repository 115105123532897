import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { themeGet } from '@styled-system/theme-get';
import shortid from 'shortid';

import { Box, Text } from './elements';
import { media } from '../shared/util';

const TOCRoot = styled(Box)`
  display: block;
  border: 1px solid ${themeGet('colors.pink')};
  border-radius: ${themeGet('radii.3')}px;
  margin-bottom: 32px;

  & ul {
    list-style: disc;
    line-height: normal;

    li {
      margin-left: 10px;
      margin-bottom: 16px;
    }
  }
  ${media.desktop`
    display: inline-block;
    float: right;
    margin-bottom: 0;
  `};
`;

const TOC = ({ data }) => (
  <TOCRoot maxWidth={280} p={3} mx={['auto', 'auto', 3]}>
    <Text as="h2" fontSize={2}>
      Contents
    </Text>
    <ul>
      {data.map(el => (
        <li key={shortid.generate()}>
          <a href={`#${el.slug}`}>{el.text}</a>
        </li>
      ))}
    </ul>
  </TOCRoot>
);

TOC.propTypes = {
  data: PropTypes.array.isRequired
};

export default TOC;
