import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import styled from '@emotion/styled';

import { Flex, Box, Text } from './elements';

const AuthorWrapper = styled(Box)`
  & .gatsby-image-wrapper {
    border-radius: 100%;
    overflow: hidden;
    filter: grayscale(100%);
  }
`;

const BlogHeader = ({ document, stats }) => {
  const { title, featured_image: featuredImage, excerpt, date } = document;
  const { text } = stats;
  const {
    document: [author]
  } = document.author;

  const { avatar } = author.data;

  return (
    <Box maxWidth={650} mx="auto" mb={4}>
      {featuredImage.localFile && (
        <Img
          fluid={featuredImage.localFile.childImageSharp.fluid}
          alt={featuredImage.alt}
        />
      )}
      <Text as="p" color="text" my={3}>
        {text}
      </Text>
      <Text as="h1" mb={3}>
        {title.text}
      </Text>
      <Text as="p" mb={3}>
        {excerpt}
      </Text>
      <Flex alignItems="center">
        <AuthorWrapper>
          <Img
            fixed={avatar.localFile.childImageSharp.fixed}
            alt={avatar.alt}
          />
        </AuthorWrapper>
        <Box ml={2}>
          <Text as="p" color="grey">
            by{' '}
            <Text as="span" fontWeight="bold" color="grey">
              {author.data.name}
            </Text>
          </Text>
          <Text as="p" color="grey">
            {date}
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};

BlogHeader.propTypes = {
  document: PropTypes.object.isRequired,
  stats: PropTypes.object.isRequired
};

export default BlogHeader;
