import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import readingTime from 'reading-time';
import slugify from '../utils/slugify';

import Layout from '../components/Layout';
import SlicesRenderer from '../components/SlicesRenderer';
import { Container } from '../components/elements';
import SEO from '../components/SEO/';
import TOC from '../components/TOC';
import BlogHeader from '../components/BlogHeader';

const BlogBody = ({ document }) => (
  <>
    <SlicesRenderer slices={document.body} />
  </>
);

BlogBody.propTypes = {
  document: PropTypes.object.isRequired
};

const BlogTemplate = ({ data }) => {
  const {
    prismicLayout: { data: layout },
    prismicBlog: { data: blog },
    prismicBlog
  } = data;

  if (!layout || !blog) return null;

  const {
    meta_title: { text: metaTitle },
    meta_description: { text: metaDescritpion }
  } = blog;

  // Collect content to calculate reading time
  let content = '';
  const toc = [];
  blog.body.forEach(slice => {
    if (slice.primary && slice.primary.content) {
      content += slice.primary.content.html;
    }
    if (
      slice.slice_type === 'copy_section' &&
      slice.primary.heading.raw &&
      slice.primary.heading.raw.length > 0
    ) {
      if (
        slice.primary.heading.raw[0].type === 'heading2' &&
        slice.primary.heading.raw[0].text.length > 0
      ) {
        toc.push({
          ...slice.primary.heading.raw[0],
          slug: slugify(slice.primary.heading.raw[0].text)
        });
        // if (slice.primary.content.raw && slice.primary.content.raw.length > 0) {
        //   slice.primary.content.raw.forEach(el => {
        //     if (el.type === 'heading3') {
        //       // console.log('h3: ', el.text);
        //     }
        //   });
        // }
      }
    }
  });
  const readingStats = readingTime(content);

  const {
    document: [author]
  } = blog.author;

  return (
    <Layout layout={layout} siteMetadata={data.site.siteMetadata} customSEO>
      <SEO
        title={metaTitle}
        desc={metaDescritpion}
        article
        node={prismicBlog}
        author={author.data.name}
        social={blog.social}
        pathname={`/blog/${prismicBlog.uid}`}
      />
      <Container
        width="100%"
        pt={120}
        pb={4}
        px="15px"
        style={{ overflow: 'hidden' }}
      >
        <BlogHeader document={blog} stats={readingStats} />
        <TOC data={toc} />
        <BlogBody document={blog} />
      </Container>
    </Layout>
  );
};

BlogTemplate.propTypes = {
  data: PropTypes.object.isRequired
};

export default BlogTemplate;

export const query = graphql`
  query BlogBySlug($uid: String!) {
    ...LayoutFragment
    site {
      siteMetadata {
        title
        author
      }
    }
    prismicBlog(uid: { eq: $uid }) {
      uid
      first_publication_date
      last_publication_date
      data {
        title {
          text
        }
        featured_image {
          alt
          localFile {
            extension
            publicURL
            childImageSharp {
              fluid(maxWidth: 650, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
        meta_description {
          text
        }
        meta_title {
          text
        }
        author {
          document {
            ... on PrismicAuthor {
              id
              data {
                name
                avatar {
                  alt
                  localFile {
                    childImageSharp {
                      fixed(width: 50, height: 50, quality: 100) {
                        ...GatsbyImageSharpFixed_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
        date(formatString: "LL")
        excerpt
        body {
          ... on PrismicBlogBodyCopySection {
            id
            slice_type
            primary {
              heading {
                html
                raw {
                  text
                  type
                }
              }
              heading_image {
                alt
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    fluid(maxWidth: 650, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                  }
                }
                dimensions {
                  height
                  width
                }
              }
              content {
                html
                raw {
                  text
                  type
                }
              }
            }
          }
          ... on PrismicBlogBodyImageSection {
            id
            slice_type
            primary {
              image {
                alt
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    fluid(maxWidth: 600, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                  }
                }
                dimensions {
                  height
                  width
                }
              }
              caption
            }
          }
        }
        social {
          ... on PrismicBlogSocialOpenGraph {
            id
            slice_type
            primary {
              og_title {
                text
              }
              og_image {
                alt
                localFile {
                  publicURL
                }
              }
              og_description {
                text
              }
            }
          }
          ... on PrismicBlogSocialTwitterCard {
            id
            slice_type
            primary {
              twitter_title {
                text
              }
              twitter_description {
                text
              }
              twitter_image {
                alt
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
    }
  }
`;
